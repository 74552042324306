import Head from 'next/head'
import Image from 'next/image'
import styles from './layout.module.css'
import Link from 'next/link'
import ctrlfLogo from '../public/images/ctrlf-logo.png'
import 'animate.css';

export const siteTitle = 'ctrl-f.dev'

const standardDescription = 'Building your tech career - one tutorial at a time'

export default function Layout({ children, title, description = standardDescription, home }) {
  let fullTitle;
  if(title) {
    fullTitle = `${siteTitle} - ${title}`;
  }
  else {
    fullTitle = siteTitle;
  }

  return (
    <div className={styles.container}>
      <Head>
        <link rel="icon" href="/favicon.png" />
        <meta
          name="description"
          property='og:description'
          content={description}
        />
        <meta
          name='image'
          property="og:image"
          content="https://ctrl-f.dev/images/ctrl-f.dev-twitter-card.png"
        />
        <meta name='title' property="og:title" content={fullTitle} />
        <meta name="og:type" content='website' />
        <meta name="twitter:title" content={fullTitle} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:image" content="https://ctrl-f.dev/images/ctrl-f.dev-twitter-card.png" />
        <meta name="twitter:image:alt" content="hands on a computer keyboard" />
        <meta name="twitter:description" content={description} />
        <meta name='author' content='Jan Schulte' />
      </Head>
      <header className={styles.header}>
        {home ? (
          <div className={styles.logo}>
            <Image className="animate__animated animate__fadeIn" src={ctrlfLogo} alt='' width='150px' height='150px' />
          </div>
        ) : (
          <div className={styles.logo}>
            <Link href="/" passHref>
              <Image src={ctrlfLogo} className={`${styles.logoLink} animate__animated animate__fadeIn`} alt='' width='150px' height='150px' />
            </Link>
          </div>
        )}
      </header>
      <main>{children}</main>
      {!home && (
        <div className={styles.backToHome}>
          <Link href="/">
            <a>← Back to home</a>
          </Link>
        </div>
      )}
      <footer>
        <small>A project by <Link href="https://twitter.com/_jan_schulte_">Jan Schulte</Link></small>
      </footer>
    </div>
  )
}
