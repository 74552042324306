import Head from 'next/head'
import Layout, { siteTitle } from '../components/layout'
import utilStyles from '../styles/utils.module.css'
import styles from './home.module.css'
import Link from 'next/link'
import Image from 'next/image'
import bannerImage from '../public/images/ctrl-f.dev.jpg'
import { loadAllTracks } from '../lib/tracks'

export default function Home({ allTracksData }) {
  return (
    <Layout home>
      <section className={`${utilStyles.headingMd} ${styles.container}`}>
        <div className={styles.hero}>
          <Image
            src={bannerImage}
            alt=""
          />
          <h1>Hand-Curated Tech Tutorials</h1>
        </div>
        <p>
          Browse the curated catalogue to find tutorials to acquire valuable skills.
        </p>
        <div className={styles.tracksList}>
          <ul>
            {allTracksData.map((track, index) => (
              <li key={index}>
                <span>{track.links.length} {track.links.length === 1 ? "Link" : "Links"}</span>
                <Link href={`/tracks/${track.slug}`} passHref><h2>{track.name}</h2></Link>
              </li>
            ))
            }
          </ul>
        </div>
      </section>
    </Layout>
  )
}

export async function getStaticProps() {
  const allTrackData = await loadAllTracks(true)

  return {
    props: {
      allTracksData: JSON.parse(JSON.stringify(allTrackData))
    },
    revalidate: 10
  }
}
